'use client';

import { BaseLink } from '../BaseLink';
import { StyledLogo } from '../Logo';
import { ResponsiveContainer } from '../ResponsiveContainer';
import {
  Container,
  FindAnFAButton,
  Header,
  HeaderButtons,
  HeaderButtonsDesktop,
  HeaderNav,
  LogoContainer,
  NavButton,
  NavigationContainer,
} from './BasicWhiteBackgroundHeader.style';
import { BasicWhiteBackgroundHeaderMobileMenu } from './BasicWhiteBackgroundHeaderMobileMenu';
import { HeaderLoginButton } from './HeaderLoginButton';

export const BasicWhiteBackgroundHeader = ({
  brandRefresh,
}: {
  brandRefresh?: boolean;
}) => {
  return (
    <Container as="header" brandRefresh={brandRefresh}>
      <ResponsiveContainer maxWidth={1440}>
        <Header>
          <LogoContainer>
            <BaseLink href="/">
              <StyledLogo />
            </BaseLink>
          </LogoContainer>

          <NavigationContainer>
            <HeaderNav>
              <NavButton href="/why-thrivent" title="Why Thrivent">
                Why Thrivent
              </NavButton>
              <NavButton href="/product-overview" title="Products & services">
                Products & services
              </NavButton>
              <NavButton
                href="/working-with-a-financial-advisor"
                title="Working with a financial advisor"
              >
                Working with a financial advisor
              </NavButton>
              <NavButton href="/giving-back" title="Giving back">
                Giving back
              </NavButton>
            </HeaderNav>

            <HeaderButtons>
              <BasicWhiteBackgroundHeaderMobileMenu
                brandRefresh={brandRefresh}
              />
            </HeaderButtons>

            <HeaderButtonsDesktop>
              <HeaderLoginButton />
              <FindAnFAButton
                variant="primary"
                href="https://local.thrivent.com"
              >
                Find financial advisors
              </FindAnFAButton>
            </HeaderButtonsDesktop>
          </NavigationContainer>
        </Header>
      </ResponsiveContainer>
    </Container>
  );
};
